var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex df-row category-wrapper"
  }, _vm._l(_vm.data, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex df-row category cursor category-margin",
      class: {
        'category-active': item.id === _vm.one.id,
        'category-deactivate': item.id !== _vm.one.id
      },
      on: {
        "click": function click($event) {
          return _vm.changeOne(item);
        }
      }
    }, [_c('p', {
      staticClass: "category-common-text",
      class: {
        'category-active-text': item.id === _vm.one.id,
        'category-deactivate-text': item.id !== _vm.one.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }