<template>
  <div class="d-flex df-row category-wrapper">
    <div
      class="d-flex df-row category cursor category-margin"
      v-for="(item, index) in data"
      :key="index"
      :class="{
        'category-active': item.id === one.id,
        'category-deactivate': item.id !== one.id
      }"
      @click="changeOne(item)"
    >
      <p
        class="category-common-text"
        :class="{
          'category-active-text': item.id === one.id,
          'category-deactivate-text': item.id !== one.id
        }"
      >
        {{ item.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      one: {}
    };
  },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  computed: {
    newData() {
      return this.data;
    }
  },
  watch: {
    newData() {
      this.one = {};
      this.one = this.data[0];
    }
  },
  created() {
    this.one = this.data[0];
  },
  methods: {
    changeOne(item) {
      this.one = item;
      this.$emit("changeOne", item);
    }
  }
};
</script>

<style lang="scss">
.category-wrapper {
  width: 100%;
  padding: 16px 0 0 0;
  flex-wrap: wrap;
}
.category {
  width: fit-content;
  min-width: 62px;
  height: 40px;
  border-radius: 8px;
  padding: 0 16px;
}
.category-margin {
  margin: 0 8px 8px 0;
}
.category-active {
  background: #54c7a2;
  border: 1px solid #54c7a2;
  box-sizing: border-box;
}
.category-deactivate {
  background: #fff;
  border: 1px solid #dadada;
  box-sizing: border-box;
}
.category-common-text {
  font-size: 16px;
  line-height: 16px;
  margin: auto;
}
.category-active-text {
  color: #fff;
}
.category-deactivate-text {
  color: #2c2c2c;
}
</style>
