var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-list-container d-flex df-col"
  }, [_c('div', {
    staticClass: "spacer"
  }), _c('div', {
    staticClass: "d-flex df-row theme-list-wrapper theme-list-mxw"
  }, [_c('div', {
    staticClass: "d-flex df-col",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h4', {
    staticClass: "menu-title"
  }, [_vm._v("테마")]), _c('p', {
    staticClass: "menu-text"
  }, [_vm._v("테마를 선택하세요.")]), _c('div', {
    ref: "solution",
    staticClass: "solution-select d-flex df-row cursor",
    on: {
      "click": _vm.solutionFloatingInfo
    }
  }, [_c('p', {
    staticClass: "solution-text"
  }, [_vm._v(_vm._s(_vm.solution.name))]), _c('img', {
    staticClass: "ico-select",
    attrs: {
      "src": require('@/assets/images/introduction/btn_down.svg')
    }
  })]), _c('category-selector', {
    attrs: {
      "data": _vm.categories
    },
    on: {
      "changeOne": _vm.changeCategory
    }
  }), _c('product', {
    attrs: {
      "data": _vm.theme
    },
    on: {
      "clickProduct": _vm.openPreview,
      "clickBtnTop": _vm.selectedTheme,
      "clickBtnBot": _vm.openPreview
    }
  })], 1)]), _c('div', {
    staticClass: "spacer"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }