var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-container"
  }, [_c('div', {
    staticClass: "product-wrapper"
  }, _vm._l(_vm.data, function (item, index) {
    var _item$thumbnail;

    return _c('div', {
      key: index,
      staticClass: "d-flex df-col",
      class: "ga_f_".concat(100 + item.id * 2 + (item.id - 3) + 2),
      on: {
        "mouseover": function mouseover($event) {
          return _vm.hoverProduct(index, true);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.hoverProduct(index, false);
        },
        "click": function click($event) {
          return _vm.clickProduct(index);
        }
      }
    }, [_c('div', {
      staticClass: "product-one d-flex df-row centered",
      class: {
        cursor: _vm.width <= 1023
      },
      style: _vm.thumbnail(item.thumbnail, index)
    }, [_vm.width > 1023 ? _c('div', {
      staticClass: "d-flex df-col hover-area"
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hover[index],
        expression: "hover[index]"
      }],
      staticClass: "d-flex hover-btn btn-top cursor",
      class: "ga_f_".concat(100 + item.id * 2 + (item.id - 3)),
      on: {
        "click": function click($event) {
          return _vm.clickBtn('top', index);
        }
      }
    }, [_c('p', {
      staticClass: "btn-text"
    }, [_vm._v("테마선택")])]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hover[index],
        expression: "hover[index]"
      }],
      staticClass: "d-flex hover-btn btn-bottom cursor",
      class: "ga_f_".concat(100 + item.id * 2 + (item.id - 3) + 1),
      on: {
        "click": function click($event) {
          return _vm.clickBtn('bot', index);
        }
      }
    }, [_c('p', {
      staticClass: "btn-text"
    }, [_vm._v("미리보기")])])]) : _vm._e(), !((_item$thumbnail = item.thumbnail) !== null && _item$thumbnail !== void 0 && _item$thumbnail.url) ? _c('img', {
      staticClass: "no-image",
      attrs: {
        "src": require('@/assets/images/introduction/ico_logo_gray.svg')
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "product-def d-flex df-row"
    }, [item.isNew ? _c('img', {
      staticClass: "badge-new",
      attrs: {
        "src": require('@/assets/images/introduction/ico_badge_new.svg')
      }
    }) : _vm._e(), _c('p', {
      staticClass: "product-title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }