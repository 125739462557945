<template>
  <div class="theme-list-container d-flex df-col">
    <div class="spacer"></div>
    <div class="d-flex df-row theme-list-wrapper theme-list-mxw">
      <div class="d-flex df-col" style="width: 100%">
        <h4 class="menu-title">테마</h4>
        <p class="menu-text">테마를 선택하세요.</p>
        <!-- 솔루션 선택 -->
        <div
          class="solution-select d-flex df-row cursor"
          ref="solution"
          @click="solutionFloatingInfo"
        >
          <p class="solution-text">{{ solution.name }}</p>
          <img
            :src="require('@/assets/images/introduction/btn_down.svg')"
            class="ico-select"
          />
        </div>
        <!-- 카테고리 선택 -->
        <category-selector :data="categories" @changeOne="changeCategory" />
        <!-- 테마목록 -->
        <product
          :data="theme"
          @clickProduct="openPreview"
          @clickBtnTop="selectedTheme"
          @clickBtnBot="openPreview"
        />
      </div>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import categorySelector from "@/components/introduction/category-selector.vue";
import product from "@/components/introduction/product.vue";
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      width: 0,
      callback: null,
      solution: {},
      category: {},
      solutions: [
        // {
        //   id: 2,
        //   name: "메이크샵",
        //   clickType: "data"
        // },
        {
          id: 3,
          name: "마이소호",
          clickType: "data"
        }
      ],
      categories: [
        {
          id: 0,
          name: "전체"
        }
      ],
      theme: []
    };
  },
  computed: {
    checkSolution() {
      return this.$route.query.id;
    },
    ...ModuleIntroCommon.mapGetters([
      "getCurrentMenuInfo",
      "getFloatingShowState",
      "getFloatingData",
      "getFloatingPosition"
    ]),
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  watch: {
    checkSolution(newVal) {
      if (newVal) {
        this.theme = [];
        this.changeSolution();
        this.resetThemeCategory();
        this.searchTheme();
      }
    }
  },
  created() {
    this.changeSolution();
    this.resetThemeCategory();
    this.searchTheme();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;

    //로그인 성공 후, 테마 선택으로 진입 시
    if (this.$route.query.theme && this.getLoginStatus) {
      this.setFullDimData(Number(this.$route.query.theme));
      this.setFullDimShowState(true);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    //솔루션 변경
    changeSolution() {
      this.setCurrentMenuInfo({
        id: 201,
        name: "테마목록",
        path: "/introduction/theme-list"
      });

      //솔루션 정보 저장
      this.solutions.forEach(item => {
        if (item.id === Number(this.$route.query.id)) {
          this.solution = item;
        }
      });
    },
    //카테고리 변경(테마 재조회)
    changeCategory(value) {
      this.category = value;
      this.theme = [];
      this.searchTheme();
    },
    //솔루션 변경 플로팅 팝업
    solutionFloatingInfo() {
      const target = this.$refs.solution;
      const {bottom, left, width} = target.getBoundingClientRect();

      let x = bottom;
      let y = left;

      this.setFloatingPosition({
        top: x + "px",
        left: y + "px",
        width: width + "px"
      });
      this.setFloatingData(this.solutions);
      this.setFloatingType("select");
      this.setFloatingShowState(true);
    },
    //테마 선택 레이어 팝업 노출(비로그인: 로그인 이동)
    selectedTheme(selected) {
      if (this.getLoginStatus) {
        this.setFullDimData(selected.themeId);
        this.setFullDimShowState(true);
      } else {
        const url = document.URL;
        const splitUrl = url.split("?");
        const params = new URLSearchParams(splitUrl[1]);
        params.set("theme", selected.themeId);
        const targetPage = splitUrl[0] + "?" + params.toString();

        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(targetPage)
        );
      }
    },
    //테마 미리보기로 이동
    openPreview(selected) {
      let routeData = this.$router.resolve({
        name: "intro-preview",
        query: {
          id: selected.themeId, //선택한 테마
          fileName: "main", //초기 셋팅할 화면값
          type: this.$route.query.type,
          siteId: this.$route.query.siteId
        }
      });
      window.open(routeData.href, "_blank");
    },
    //테마 카테고리 초기화 함수
    resetThemeCategory() {
      this.categories = [
        {
          id: 0,
          name: "전체"
        }
      ];
      this.category = {
        id: 0,
        name: "전체"
      };
      this.themeCategory();
    },
    //테마 카테고리 목록 조회
    themeCategory() {
      let payload = {};
      payload.solutionId = this.solution.id;
      payload.visible = 1;

      this.actThemeCategory(payload).then(response => {
        switch (response.status) {
          case 200:
            response.data.forEach(item => {
              if (item.existsTheme) {
                this.categories.push(item);
              }
            });
            break;

          default:
            break;
        }
      });
    },
    //테마 목록 조회
    searchTheme() {
      let payload = {};
      payload.solutionId = this.solution.id;
      payload.display = 1;
      payload.sortBy = "id-";
      if (this.category.id !== 0) payload.categoryId = this.category.id;

      this.actThemeCatalog(payload).then(response => {
        switch (response.status) {
          case 200:
            response.data.forEach(item => {
              if (item.display === 1) {
                //신규상품 여부 계산
                const themeCreated = new Date(
                  this.timestampFilter(item.createdAt, "timestamp")
                );
                const today = new Date();
                const gap = Math.floor(
                  (today.getTime() - themeCreated.getTime()) /
                    (1000 * 3600 * 24)
                );
                //신규상품 여부 컬럼 추가
                gap < 90
                  ? (item.product["isNew"] = true)
                  : (item.product["isNew"] = false);

                item.product["themeId"] = item.id;

                this.theme.push(item.product);
              }
            });
            break;

          default:
            break;
        }
      });
    },
    // 날짜형식 필터링
    timestampFilter(value, type) {
      if (value === "") return "";

      let date = new Date(value);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      let result =
        type === "timestamp"
          ? year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds
          : year + "-" + month + "-" + day;

      return result;
    },
    ...ModuleIntroCommon.mapMutations([
      "setCurrentMenuInfo",
      "setFloatingShowState",
      "setFloatingData",
      "setFloatingType",
      "setFloatingPosition",
      "setFullDimShowState",
      "setFullDimData"
    ]),
    ...ModuleTheme.mapActions(["actThemeCategory", "actThemeCatalog"]),
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    categorySelector,
    product
  }
};
</script>

<style lang="scss">
.menu-title {
  font-size: 32px;
  line-height: 32px;
  color: #484948;
  font-weight: 500;
}
.menu-text {
  font-size: 14px;
  line-height: 14px;
  color: #484948;
  margin: 16px 0 0 0;
}
.solution-select {
  width: 100px;
  height: 36px;
  margin: 20px 0 0 0;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  border-radius: 8px;
}
.solution-text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin: auto;
  color: #8d8d8d;
}
.ico-select {
  margin: 0 auto;
}
.theme-list-container {
  width: 100%;
  background: #fff;
  @media screen and (min-width: 1024px) {
    pwidth: 100%;
    background: #fff;
    align-items: center;
  }
}
.theme-list-wrapper {
  padding: 34px 16px 40px;
  @media screen and (min-width: 1024px) {
    padding: 48px 16px 40px 24px;
  }
}
.solution-select {
  margin: 46px 0 0 0;
}

@media (min-width: 576px) {
  .theme-list-mxw {
    //max-width: 540px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media (min-width: 768px) {
  .theme-list-mxw {
    //max-width: 720px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media (min-width: 992px) {
  .theme-list-mxw {
    //max-width: 960px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media (min-width: 1200px) {
  .theme-list-mxw {
    max-width: 1248px;
    width: 100%;
  }
}
</style>
