<template>
  <div class="product-container">
    <div class="product-wrapper">
      <div
        class="d-flex df-col"
        :class="`ga_f_${100 + item.id * 2 + (item.id - 3) + 2}`"
        v-for="(item, index) in data"
        :key="index"
        @mouseover="hoverProduct(index, true)"
        @mouseleave="hoverProduct(index, false)"
        @click="clickProduct(index)"
      >
        <div
          class="product-one d-flex df-row centered"
          :class="{cursor: width <= 1023}"
          :style="thumbnail(item.thumbnail, index)"
        >
          <div class="d-flex df-col hover-area" v-if="width > 1023">
            <div
              class="d-flex hover-btn btn-top cursor"
              :class="`ga_f_${100 + item.id * 2 + (item.id - 3)}`"
              v-show="hover[index]"
              @click="clickBtn('top', index)"
            >
              <p class="btn-text">테마선택</p>
            </div>
            <div
              class="d-flex hover-btn btn-bottom cursor"
              :class="`ga_f_${100 + item.id * 2 + (item.id - 3) + 1}`"
              v-show="hover[index]"
              @click="clickBtn('bot', index)"
            >
              <p class="btn-text">미리보기</p>
            </div>
          </div>
          <img
            v-if="!item.thumbnail?.url"
            :src="require('@/assets/images/introduction/ico_logo_gray.svg')"
            class="no-image"
          />
        </div>
        <div class="product-def d-flex df-row">
          <img
            v-if="item.isNew"
            :src="require('@/assets/images/introduction/ico_badge_new.svg')"
            class="badge-new"
          />
          <p class="product-title">
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

export default {
  data() {
    return {
      width: 0,
      hover: []
    };
  },
  props: {
    data: {
      type: Array,
      require: true
    }
  },
  computed: {
    newList() {
      return this.data;
    },
    ...ModuleIntroCommon.mapGetters(["getCurrentMenuInfo"])
  },
  watch: {
    newList() {
      this.hover = [];
      this.data.forEach((item, index) => {
        this.$set(this.hover, index, false);
      });
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    //상품 hover 시 (데스크탑)
    hoverProduct(index, state) {
      this.$set(this.hover, index, state);
    },
    //상품 hover 버튼 클릭시 (데스크탑)
    clickBtn(type, index) {
      switch (type) {
        case "top":
          this.$emit("clickBtnTop", this.data[index]);
          break;
        case "bot":
          this.$emit("clickBtnBot", this.data[index]);
          break;
      }
    },
    //상품 클릭시 (모바일)
    clickProduct(index) {
      if (this.width > 1023) return false;
      this.$emit("clickProduct", this.data[index]);
    },
    //썸네일 이미지 배경 삽입(hover 처리 포함)
    thumbnail(thumbnail, index) {
      let hovered = null;
      let normal = null;

      normal =
        !thumbnail || !thumbnail.url
          ? "background: #f0f0f0"
          : "background:url(" + thumbnail.url + ") no-repeat ";

      if (this.width > 1023) {
        hovered =
          !thumbnail || !thumbnail.url
            ? "background: rgba(0, 0, 0, 0.4)"
            : "background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ),url(" +
              thumbnail.url +
              ");";
      }

      return this.width > 1023
        ? !this.hover[index]
          ? normal
          : hovered
        : normal;
    },
    ...ModuleIntroCommon.mapMutations(["setCurrentMenuInfo"])
  }
};
</script>

<style lang="scss">
.product-container {
  width: 100%;
  object-fit: fill;
  margin: 16px 0 0 0;
}
.product-wrapper {
  width: 100%;
  display: grid;
  align-content: center;
}
.product-one {
  position: relative;
  border-radius: 8px;
  background-size: 100% 100% !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}
.hover-area {
  z-index: 1;
}
.no-image {
  position: absolute;
}
.product-wrapper {
  column-gap: 16px;
  row-gap: 16px;
  @media screen and (min-width: 1024px) {
    column-gap: 24px;
    row-gap: 48px;
  }
}
.product-def {
  margin: 13px 0 0 0;
  align-items: baseline;
  @media screen and (min-width: 1024px) {
    margin: 18px 0 0 0;
    align-items: baseline;
  }
}
.badge-new {
  margin: 0 8px 0 0;
  width: 17px;
  height: 18px;
  align-items: flex-start;
  @media screen and (min-width: 1024px) {
    margin: auto 4px auto 0;
    align-items: flex-start;
  }
}
.product-title {
  font-size: 14px;
  line-height: 16px;
  align-self: center;
  @media screen and (min-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
    align-self: center;
  }
}
.hover-btn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
}
.btn-text {
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  margin: auto;
}
.btn-top {
  background: #54c7a2;
  margin: 0 0 16px 0;
}
.btn-bottom {
  border: 1px solid #fff;
  box-sizing: border-box;
}

.product-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(44.17%, auto));
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(45.31%, auto));
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(30.2%, auto));
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(auto-fill, minmax(22.78%, auto));
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(21.91%, auto));
  }
}
.product-one {
  height: 246px;
  @media screen and (min-width: 576px) {
    height: 353px;
  }
  @media screen and (min-width: 768px) {
    height: 314px;
  }
  @media screen and (min-width: 992px) {
    height: 399px;
  }
  @media screen and (min-width: 1200px) {
    height: 372px;
  }
}
.no-image {
  width: 112px;
  height: 36px;
  @media screen and (min-width: 576px) {
    width: 112px;
    height: 36px;
  }
  @media screen and (min-width: 768px) {
    width: 143px;
    height: 45px;
  }
  @media screen and (min-width: 992px) {
    width: 139px;
    height: 44px;
  }
  @media screen and (min-width: 1200px) {
    width: 169px;
    height: 54px;
  }
}
</style>
